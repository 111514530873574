"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function select2Utilities() {
    var $ = window.$;
    var elements = [].slice.call(document.querySelectorAll('[data-control="mt_select2"]'));
    // eslint-disable-next-line array-callback-return
    elements.map(function (element) {
        if (element.getAttribute('data-kt-initialized') === '1') {
            // eslint-disable-next-line array-callback-return
            return;
        }
        $(element).select2({
            tags: true
        });
    });
}
exports.default = select2Utilities;
